// import Navbar from "react-bootstrap/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import Logo from "../public/logo-purple.png";

import React from "react";
import { Navbar, Nav } from "react-bootstrap";

export default function MyHeaderBare() {
  return (
    <Navbar
      style={{
        "background-color": "#FFFFFF",
        "box-shadow": "0 2px 4px 0 rgba(0,0,0,.05)",
        "font-family": "sans-serif",
        "border-bottom": "3px solid #614CA9",
      }}
      bg="light"
      variant="light"
      fixed="top"
      id="myHeader"
      expand="lg"
    >
      <Navbar.Brand
        style={{ "margin-left": "1.5rem", "background-color": "#FFFFFF" }}
        href="https://simplecgt.com"
      >
        <img
          src={Logo}
          height="36"
          className="d-inline-block align-top"
          alt="SimpleCGT logo"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse
        id="basic-navbar-nav"
        style={{ justifyContent: "flex-end" }}
      >
        <Nav className="ms-auto" style={{ alignItems: "flex-end" }}>
          <Nav.Link href="https://simplecgt.com/cgt" target="_blank">
            Visit App
          </Nav.Link>
          <Nav.Link href="https://simplecgt.com/register" target="_blank">
            Register
          </Nav.Link>
          <Nav.Link href="https://simplecgt.com/login" target="_blank">
            Login
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
