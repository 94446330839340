import React, {Fragment} from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';


export default function MyJumbo() {


  return (
    <Fragment>
      <Jumbotron
        style={{
          "margin-top": "85px",
          padding: "1rem",
          border: "2px solid rgba(0,0,0,.125",
          "border-radius": ".5rem",
          "background-color": "#FFFFFF",
          "font-family": "sans-serif",
        }}
      >
        <h2 style={{ textAlign: "center", paddingBottom: 14 }}>
          RTSO1 calculator
        </h2>
        <h5 style={{ textAlign: "center" }}>
          Would you rather have your CGT and RTSO1 tax calulated automatically
          by importing your transaction history? Then check out{" "}
          <a href="https://www.simplecgt.com">simplecgt.com</a> for free!
        </h5>
        <br />
        <h6 style={{ textAlign: "center", color: "grey" }}>
          *This tool is for educational purposes only. You must ensure the
          accuracy of your own calculations.*
        </h6>
        <h6 style={{ textAlign: "center", color: "grey" }}>
          Problems for questions? Reach out at{" "}
          <a href="mailto:team@simplecgt.com">team@simplecgt.com</a>
        </h6>
        <br />
        <hr />
        <h6 style={{ textAlign: "left", color: "grey" }}>How to use:</h6>
        <span style={{ fontSize: "12px", color: "grey" }}>
          - Select your <b>Managed Company</b> from the drop down list (top
          right), or Select <b>Free Input / Stock Options</b>.
          <br />- <b>Enter details</b>. If "managed", simply add units you
          received.
          <br />- Click <b>"Generate RTSO1 form"</b> & fill in details. We will
          fill it out and add a signature.
          <br />
          <br />- <b>Submit</b> this pdf to Revenue via "myEnquiries" on
          myAccount or ROS.
          <br />- <b>Pay</b> via Revenue's MyAccount: go to "Make a Payment" >
          Tax > Relevant Tax Share Option > Enter year and the figure under
          "Total Liability"
          <br />
          <br />
          <b>Note</b>: you will likely need to file a <b>Form 11</b> with
          Revenue. <br />
          The deadline for this is not until the <b>end</b> of the{" "}
          <b>following year</b>, (we PAY IN the period, but FILE AFTER the
          period).
          <br />
          Ensure you are registered for ROS, choosing CGT as your tax type. More
          guides and tutorials{" "}
          <a href="https://simplecgt.com/documentation" target="_blank">
            here
          </a>
        </span>
        {/* <p style={{textAlign: "center"}}> This is a manual tool for any one who doesn't want to use the main site.</p> */}
        {/* <p> */}
        {/* <Button variant="primary">Go to CGT dashboard</Button> */}
        {/* </p> */}
      </Jumbotron>
    </Fragment>
  );
}