import React, { useState, Fragment } from "react";
import { useSelector } from "react-redux";

import moment from "moment";

import { Radio } from 'antd';
// import 'antd/dist/antd.css';

import EsppTabs from "./managed/EsppTabs";
import EsppTabs_Sandbox from "./sandbox/EsppTabs_Sandbox";

export default function () {
  const [sandbox, setSandbox] = useState(false)

  const handleSandboxChange = (e) => {
    setSandbox(e.target.value)
  }

  // const esppRowData = useSelector((state) => state.espp);

  //TODO: move to utils
  // function groupBy(list, keyGetter) {
  //   const map = new Map();
  //   list.forEach((item) => {
  //     const key = keyGetter(item);
  //     const collection = map.get(key);
  //     if (!collection) {
  //       map.set(key, [item]);
  //     } else {
  //       collection.push(item);
  //     }
  //   });
  //   return map;
  // }

  // const grouped = groupBy(esppRowData, (row) =>
  //   moment(row.date).format("YYYY")
  // );

  // const mapentries = Array.from(grouped.entries());

  return (
    <Fragment>
      <Radio.Group
        value={sandbox}
        onChange={handleSandboxChange}
        style={{ color: "#614CA9" }}
      >
        <Radio.Button value={true} style={{ color: "#614CA9" }}>
          Free Input / Stock Options
        </Radio.Button>
        <Radio.Button value={false} style={{ color: "#614CA9" }}>
          Centrally Managed ESPP
        </Radio.Button>
      </Radio.Group>
      {sandbox ? (
        <EsppTabs_Sandbox />
      ) : (
        // : <EsppTabs mapentries={mapentries} />
        <EsppTabs />
      )}
    </Fragment>
  );
}
