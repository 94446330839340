import React from "react";

import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import Card from "react-bootstrap/Card";

import EsppTab_Sandbox from "./EsppTab_Sandbox";

export default function EsppTabs_Sandbox() {

  return (
    <Card
      style={{
        "border-radius": ".5rem",
        "box-shadow": "0 2px 4px 0 rgba(0,0,0,.05)",
      }}
    >
      <Tab.Container id="controlled-tab-example" activeKey={"year"}>
        <Card.Header>
          <Nav
            className="container-fluid"
            variant="tabs"
            style={{ "border-bottom": "0px" }}
          >
            <Nav.Item>
              <Nav.Link eventKey={"year"} style={{ color: "#614CA9" }}>
                Year
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Card.Header>
        <Card.Body>
          <Tab.Content animation>
            <EsppTab_Sandbox />
          </Tab.Content>
        </Card.Body>
      </Tab.Container>
    </Card>
  );
}