import React, { useState, useEffect, useRef, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { css, cx } from '@emotion/css'

import Tab from "react-bootstrap/Tab";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";

import EsppDepositRow from "./EsppDepositRow";
import { Rtso1Form11CardBody, calcTotalsForForm11 } from "../Rtso1Form11";


  const EsppTabPane = function (props) {
    const { year, rows, company, esppRowData } = props;

    const [totalLiab, setTotalLiab] = useState(0);
    const [totalAmountGain, setTotalAmountGain] = useState(0);

    // const esppRowData = useSelector((state) => state.espp);

    /* updateTotalLiab */
    useEffect(() => {
      calcTotalsForForm11(
        esppRowData,
        rows,
        setTotalLiab,
        setTotalAmountGain
      );
    }, [esppRowData]);

    return (
      <Tab.Pane eventKey={year}>
        <Table className={tableStyle}>
          <thead className={headerStyle}>
            <th>Deposit Date</th>
            <th>$ Acquired Price</th>
            <th style={{ borderRight: "2px solid #dee2e6" }}>
              € Acquired Price
            </th>
            <th>$ FMV</th>
            <th style={{ borderRight: "2px solid #dee2e6" }}>€ FMV</th>
            <th>Units</th>
            <th>Tax Rate</th>
            <th><b>€ Total Liability <br/>(pay to revenue)</b></th>
            <th>€ Amount of Gain Made</th>
            <th>RTSO to be paid by</th>
            <th>Days left to pay</th>
            <th></th>
          </thead>

          {company !== "none" ? (
            rows.map((row) => (
              <EsppDepositRow row={row} key={row.date} company={company} />
            ))
          ) : (
            <EsppDepositRow />
          )}
        </Table>

        <Card
          style={{
            "border-radius": ".5rem",
            "box-shadow": "0 2px 4px 0 rgba(0,0,0,.05)",
          }}
        >
          <Card.Header as="h4" >For Form 11</Card.Header>
          <Rtso1Form11CardBody
            totalAmountGain={totalAmountGain}
            totalLiab={totalLiab}
          />
        </Card>
      </Tab.Pane>
    );
  };



export default function EsppTab(props) {
  const { esppRowData, mapentries, company } = props;

  return (
    <Fragment>
      {mapentries.map((entry) => (
        <EsppTabPane
          year={entry[0]}
          rows={entry[1]}
          company={company}
          esppRowData={esppRowData}
        />
      ))}
    </Fragment>
  );
}


export const tableStyle = css`
  display: block;
  overflow: auto;
  font-family: sans-serif;
  font-weight: 250;
  margin-bottom: 20px;
`
export const headerStyle = css`
th {
min-width: 85px;
border: none;
font-family: sans-serif;
color: #7c858e;
font-weight: 540;  }
`

export const columnGroupingStyle = css`
  border-left: 1px solid #dee2e6; 
`