import React from "react";
import Card from "react-bootstrap/Card";

import moment from "moment";



export const Rtso1Form11CardBody = (props) => {
    const { totalAmountGain, totalLiab } = props;
    return (
        <Card.Body>

            <Card.Title as="h6">Total chargeable amount</Card.Title>
            <Card.Text>{totalAmountGain.toFixed(2)}</Card.Text>

            <Card.Title as="h6">
                Relevant Tax on a Share Option (RTSO) paid
    </Card.Title>
            <Card.Text>{totalLiab.toFixed(2)}</Card.Text>

            <footer className="blockquote-footer">
                Numbers summed on a <cite>per year</cite> basis
    </footer>
        </Card.Body>
    )
}

export function calcTotalsForForm11 (incomingState, rows, setTotalLiabIn, setTotalAmountGainIn) {
    let rowsX = incomingState.filter((currentRow) =>
        rows ? moment(currentRow.date).isSame(moment(rows[0]?.date), "year")
        : true
    );

    const { tempTotalLiab, tempTotalAmountGain } = rowsX.reduce(
        (
            { tempTotalLiab, tempTotalAmountGain },
            { totalLiab, amountGain }
        ) => ({
            tempTotalLiab: tempTotalLiab + totalLiab,
            tempTotalAmountGain: tempTotalAmountGain + amountGain,
        }),
        { tempTotalLiab: 0, tempTotalAmountGain: 0 }
    );

    setTotalLiabIn(tempTotalLiab);
    setTotalAmountGainIn(tempTotalAmountGain);
};