import React, { useState } from "react";
import { useSelector } from "react-redux";

import moment from "moment";
import { Select } from 'antd';

import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import Card from "react-bootstrap/Card";

import EsppTab from "./EsppTab";

export default function EsppTabs(props) {
  // const {mapentries} = props;
  const currentYear = moment().format("YYYY");
  const [key, setKey] = useState(currentYear);

  const [company, setCompany] = useState("wday");
  const esppRowData = useSelector((state) => state.espp[company]);
console.log('esppRowData', esppRowData);



    function groupBy(list, keyGetter) { //TODO: move to utils
      const map = new Map();
      list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
          map.set(key, [item]);
        } else {
          collection.push(item);
        }
      });
      return map;
    }
    const grouped = groupBy(esppRowData, (row) =>
      moment(row.date).format("YYYY")
    );

    const mapentries = Array.from(grouped.entries());

    console.log('mapentries', mapentries)


  const handleCompanyChange = (value) => {
    setCompany(value)
  }

  const companySelect =  (
      <Select
        defaultValue="Workday"
        style={{ width: 190 }}
        onChange={handleCompanyChange}
      >
        <Select.Option value="udmy" style={{ fontSize: "13px" }}>
          Udemy
        </Select.Option>
        <Select.Option value="wday" style={{ fontSize: "13px" }}>
          Workday
        </Select.Option>
        <Select.Option value="disabled" disabled style={{ fontSize: "13px" }}>
          Contact for Management
        </Select.Option>
      </Select>
    );
  ;

  return (
    <Card
      style={{
        "border-radius": ".5rem",
        "box-shadow": "0 2px 4px 0 rgba(0,0,0,.05)",
      }}
    >
      <Tab.Container
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
      >
        <Card.Header>
          <Nav
            className="container-fluid"
            variant="tabs"
            style={{ "border-bottom": "0px" }}
          >
            {/* mapped years as per slice */}
            {mapentries.map((entry) => (
              <Nav.Item>
                <Nav.Link eventKey={entry[0]} style={{ color: "#614CA9" }}>
                  {entry[0]}
                </Nav.Link>
              </Nav.Item>
            ))}

            <Nav.Item className="ml-auto">{companySelect}</Nav.Item>
          </Nav>
        </Card.Header>
        <Card.Body>
          <Tab.Content animation>
            {/* TODO: can just call mapentries.map EsppTabPane directly?  */}
            {EsppTab({
              esppRowData: esppRowData,
              mapentries: mapentries,
              company: company,
            })}
            {/* props are mapentries(i.e esppData) & headers */}
          </Tab.Content>
        </Card.Body>
      </Tab.Container>
    </Card>
  );
}