import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import axios from "axios"
import moment from "moment";

import { css, cx } from '@emotion/css'
import Button from "react-bootstrap/Button";
import { Switch, InputNumber, DatePicker, Tooltip } from 'antd';
import 'antd/dist/antd.css';



import { updateSandboxRow } from "./sandboxSlice";
import TaxRateSelect from "../TaxRateSelect";
import GeneratePdfModal from "../GeneratePdfModal";

import { submitExchangeRate, retrieve } from "../api";


export default function EsppDepositRowSandbox(props) {
  const today = new Date();

  const [units, setUnits] = useState(0);
  const [fmvUSD, setFmvUSD] = useState(0.00);
  const [fmvEUR, setFmvEUR] = useState(0.00);
  const [acquiredPriceUSD, setAcquiredPriceUSD] = useState(0.00);
  const [acquiredPriceEUR, setAcquiredPriceEUR] = useState(0.00);

  const [taxRate, setTaxRate] = useState(52);
  const [amountGain, setAmountGain] = useState(0);
  const [totalLiab, setTotalLiab] = useState(0);
  const [daysToPay, setDaysToPay] = useState("--");
  const [acquiredDate, setAcquiredDate] = useState(moment());
  const [depositDate, setDepositDate] = useState(moment());
  const [exchangeRateDown, setExchangeRateDown] = useState(false); // Eur to USD, 1/this gives usd to Eur
  const [noCacheHit, setNoCacheHit] = useState(false); // Eur to USD, 1/this gives usd to Eur //TODO: defaul it based on incoming parm

  const [acquiredEURSwitch, setAcquiredEURSwitch] = useState(true)
  const handleAcquiredEURSwitch = (checked) => { //if checked, then euro
    !checked && setAcquiredPriceUSD(0.0) //switching to USD
    checked && setAcquiredPriceEUR(0.0) //switching to EUR
    setAcquiredEURSwitch(checked)
  }

  const [fmvEURSwitch, setFmvEURSwitch] = useState(true)
  const handleFmvEURSwitch = (checked) => {
    !checked && setFmvUSD(0) //switching to USD
    checked && setFmvEUR(0) //switching to EUR
    setFmvEURSwitch(checked)
  }

  // function getMomentFormatter(format, locale) {
  //   // note that locale argument comes from locale prop and may be undefined
  //   return {
  //     formatDate: (date, locale) => moment(date).locale(locale).format(format),
  //     parseDate: (str, locale) => moment(str, format).locale(locale).toDate(),
  //     placeholder: format,
  //   }
  // };

  const dispatch = useDispatch();

  /* updateDaysToPay */
  useEffect(() => {
    let ddate = moment(acquiredDate);
    let datePlus = ddate.add(30, "days");
    let now = moment();
    let daysLeft = datePlus.diff(now, "days");
    setDaysToPay(daysLeft < -365 ? "--" : daysLeft);
  });

  /* updateTotalLiab */
  useEffect(() => {
    if (units) {
      const blepamountgain = (fmvEUR - acquiredPriceEUR) * units
      setAmountGain(() => blepamountgain);
      setTotalLiab(blepamountgain * (taxRate / 100));
    } else {
      setAmountGain(() => 0);
      setTotalLiab(0);
    }
  }, [fmvEUR, acquiredPriceEUR, units, taxRate]);

  /* dispatchAmountGainAndTotalLiab */
  useEffect(() => {
    dispatch(
      updateSandboxRow({
        id: props.id,
        units: units,
        amountGain: amountGain,
        totalLiab: totalLiab,
      })
    );
  }, [totalLiab]);

  useEffect(() => {   // on page load, get ERs for today
    getExchangeRate(today)
  }, [])
  

  async function fetchExchangeRate_submitToDB_addToCache(dateKey) { //TODO: split method
    await retrieve("getER", dateKey)
    .then((response)=>{
      if (response.data.error) throw response.data.error
      submitExchangeRate(dateKey, 1 / response.data.rates.USD)
      props.addExchangeRateToCache(dateKey, 1/response.data.rates.USD)
      return 1/response.data.rates.USD
      
    })
    .catch((error)=>{
      console.log("error: fetch ER - row", {error});
      setExchangeRateDown(true)
    })
  }

  async function getExchangeRate(date) { //get rate from cache is exists, else fetch it
    setNoCacheHit((currentState) => currentState = false)
    const dateKey = moment(date).format('YYYY-MM-DD')
    if (props.getExchangeRateFromCache(dateKey)) {
      let erFromCache = props.getExchangeRateFromCache(dateKey)
      return erFromCache
    }
    else {
      console.log("nch");
      setNoCacheHit((currentState) => currentState = true)
      setExchangeRateDown((currentState) => currentState = false)
      try {
        let erResponse = await fetchExchangeRate_submitToDB_addToCache(dateKey)
        return  erResponse//else fetch it
      } catch (error) {
        console.log("no fetch response");
        console.log("error", error);
      }
    }
  }


  async function handleAcquiredDateChange(_date) {
    if (!_date.isSame(acquiredDate)) {
      setAcquiredDate(_date)
      const exchangeRate = await getExchangeRate(_date)  //get rate from cache is exists, else fetch it
      if (exchangeRate) {
        acquiredEURSwitch ? setAcquiredPriceUSD(acquiredPriceEUR * (1 / exchangeRate)) //TODO: 1/rate?
          : setAcquiredPriceEUR(acquiredPriceUSD * exchangeRate) //TODO: 1/rate?
      }
      else {
        setAcquiredPriceUSD(0)
        setAcquiredPriceEUR(0)
        setAcquiredEURSwitch(true)
      }
    }
  }
  async function handleDepositDateChange(_date) {
    if (!_date.isSame(depositDate)) {
      setDepositDate(_date)
      const exchangeRate = await getExchangeRate(_date)
      if (exchangeRate) {
        fmvEURSwitch ? setFmvUSD(fmvEUR *  (1 / exchangeRate))
          : setFmvEUR(fmvUSD *  exchangeRate)
      }
      else {
        setFmvUSD(0)
        setFmvEUR(0)
        setFmvEURSwitch(true)
      }
    }
  }

  const handleUnitsChange = (value) => {
    setUnits(parseInt(value) || 0); //no ()=> as not working with previou value, just replacing with new value
  };

  // Acquired
  const handleAcquiredPriceEURChange = (value) => {
    setAcquiredPriceEUR(parseFloat(value) || 0);
    const er = props.getExchangeRateFromCache(moment(acquiredDate).format('YYYY-MM-DD'))
    er && setAcquiredPriceUSD(parseFloat(value) * (1 / er) || 0);
  };

  const handleAcquiredPriceUSDChange = (value) => {
    setAcquiredPriceUSD(parseFloat(value) || 0);
    const er = props.getExchangeRateFromCache(moment(acquiredDate).format('YYYY-MM-DD'))
    er && setAcquiredPriceEUR(parseFloat(value) * er || 0);
  };

  // FMV
  const handleFmvEurChange = (value) => {
    setFmvEUR(parseFloat(value) || 0);
    const er = props.getExchangeRateFromCache(moment(depositDate).format('YYYY-MM-DD'))
    er && setFmvUSD(parseFloat(value) * (1 / er) || 0)
  };

  const handleFmvUSDChange = (value) => {
    setFmvUSD(parseFloat(value) || 0);
    const er = props.getExchangeRateFromCache(moment(depositDate).format('YYYY-MM-DD'))
    er && setFmvEUR(parseFloat(value) * er || 0);

  };


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const totalLiabilityRef = useRef();


  //TODO: make method that returns inputs / date picker components
  return (
    <tr>
      {/* Acquired */}
      <td className={dateStyle}>
        <DatePicker
          allowClear={false}
          defaultValue={moment(today, "dd/MM/yyyy")}
          format="DD/MM/yyyy"
          onChange={handleAcquiredDateChange}
          disabledDate={(current) =>
            current &&
            (current > moment().endOf("day") || current > depositDate)
          }
        />
      </td>
      <td className={currency}>
        <InputNumber
          className={noCacheHit && exchangeRateDown && strike}
          value={
            acquiredEURSwitch ? acquiredPriceUSD.toFixed(2) : acquiredPriceUSD
          }
          disabled={acquiredEURSwitch}
          onChange={handleAcquiredPriceUSDChange}
          formatter={(value) =>
            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
          min={0}
          max={10000000}
        />
      </td>
      <td>
        <Switch
          checked={acquiredEURSwitch}
          onChange={handleAcquiredEURSwitch}
          defaultChecked
          disabled={noCacheHit && exchangeRateDown}
          style={{ backgroundColor: "#614CA9" }}
        />
      </td>
      <td className={currency}>
        <InputNumber
          className={noCacheHit && exchangeRateDown && strike}
          value={
            acquiredEURSwitch ? acquiredPriceEUR : acquiredPriceEUR.toFixed(2)
          }
          disabled={!acquiredEURSwitch}
          onChange={handleAcquiredPriceEURChange}
          formatter={(value) =>
            `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          parser={(value) => value.replace(/\€\s?|(,*)/g, "")}
          min={0}
          max={10000000}
        />
      </td>

      {/* Sold */}
      <td className={cx(columnGroupingStyle, dateStyle)}>
        <DatePicker
          allowClear={false}
          defaultValue={moment(today, "dd/MM/yyyy")}
          format="DD/MM/yyyy"
          onChange={handleDepositDateChange}
          disabledDate={(current) =>
            current &&
            (current > moment().endOf("day") || current < acquiredDate)
          }
        />
      </td>
      <td className={currency}>
        <InputNumber
          className={exchangeRateDown && strike}
          value={fmvEURSwitch ? fmvUSD.toFixed(2) : fmvUSD}
          disabled={fmvEURSwitch}
          onChange={handleFmvUSDChange}
          formatter={(value) =>
            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
          min={0}
          max={10000000}
        />
      </td>
      <td>
        <Switch
          checked={fmvEURSwitch}
          onChange={handleFmvEURSwitch}
          defaultChecked
          disabled={noCacheHit && exchangeRateDown}
          style={{ backgroundColor: "#614CA9" }}
        />
      </td>
      <td className={currency}>
        <InputNumber
          value={fmvEURSwitch ? fmvEUR : fmvEUR.toFixed(2)}
          disabled={!fmvEURSwitch}
          onChange={handleFmvEurChange}
          formatter={(value) =>
            `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          parser={(value) => value.replace(/\€\s?|(,*)/g, "")}
          min={0}
          max={10000000}
        />
      </td>

      <td className={columnGroupingStyle}>
        <InputNumber
          value={units}
          onChange={handleUnitsChange}
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
          min={0}
          max={1000000}
        />
      </td>
      <td>
        <TaxRateSelect
          taxRate={taxRate}
          handleTaxRateChange={(value) => setTaxRate(parseInt(value))}
        />
      </td>

      <td id="totalLiability" ref={totalLiabilityRef}>
        {totalLiab.toFixed(2)}
      </td>
      <td id="amountGain">{amountGain.toFixed(2)}</td>
      <td className={noWrapStyle}>
        {moment(acquiredDate).add(30, "days").format("YYYY-MM-DD")}
      </td>
      <td>{daysToPay}</td>

      <td>
        <Button
          className={generatePdfButtonStyle}
          variant="primary"
          size="sm"
          onClick={handleShow}
          style={{ backgroundColor: "#614CA9" }}
        >
          Generate RTSO1 form
        </Button>
      </td>
      <GeneratePdfModal
        date={acquiredDate}
        amountGain={amountGain}
        totalLiab={totalLiab}
        show={show}
        extProps={props}
        handleClose={handleClose}
      />
    </tr>
  );
}

const columnGroupingStyle = css`
  border-left: 1px solid #dee2e6; 
`
const noWrapStyle = css`
white-space: no-wrap;
`
const dateStyle = css`
min-width: 140px;
`
const currency = css`
display: flex;
`
const strike = css`
text-decoration: line-through;
`
const generatePdfButtonStyle = css`
margin: 0px;
padding: 1px;
width: 100px;
`