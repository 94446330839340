import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import { render } from "react-dom";
import { Provider } from "react-redux";
// import store from "./js/store/index";
import store from "./app/store";
// import App from "./js/components/App";
import './App.scss';

ReactDOM.render(
  <Provider store = {store}>
    <App style={{"padding": "27px"}}/>
  </Provider>,
  document.getElementById('root')
);

