import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import moment from "moment";
import { css, cx } from '@emotion/css'

import Button from "react-bootstrap/Button";
import { InputNumber } from 'antd';

import { updateEsppRow } from "./esppSlice";
import TaxRateSelect from "../TaxRateSelect";
import GeneratePdfModal from "../GeneratePdfModal";


export default function EsppDepositRow(props) {
  var fs = require("fs");
  const [units, setUnits] = useState(0);
  const [date, setDate] = useState(new Date()); //TODO: acquiredDate?
  // const [date, setDate] = useState(moment().format("MM-DD-YYYY"));
  const [fmvEUR, setFmvEur] = useState(0.00);
  const [acquiredPriceEUR, setAcquiredPriceEUR] = useState(0.00);
  
  const [taxRate, setTaxRate] = useState(52);
  const [amountGain, setAmountGain] = useState(0);
  const [totalLiab, setTotalLiab] = useState(0);
  const [daysToPay, setDaysToPay] = useState("--");

  function getMomentFormatter(format, locale) {
    // note that locale argument comes from locale prop and may be undefined
    return {
        formatDate: (date, locale) => moment(date).locale(locale).format(format),
        parseDate: (str, locale) => moment(str, format).locale(locale).toDate(),
        placeholder: format,
    }
};

  const dispatch = useDispatch();

  /* updateDaysToPay */
  useEffect(() => {
    let ddate = moment(props.row ? props.row.date : date);
    let datePlus = ddate.add(30, "days");
    let now = moment();
    let daysLeft = datePlus.diff(now, "days");
    setDaysToPay(daysLeft < -365 ? "--" : daysLeft);
  });

  const esppRowData = useSelector((state) => state.espp);

  /* updateTotalLiab */
  useEffect(() => {
    if (units) {
      const blepamountgain = props.row ?
        (props.row.fmvEUR - props.row.acquiredPriceEUR) * units
        : (fmvEUR - acquiredPriceEUR) * units
      setAmountGain(() => blepamountgain);
      setTotalLiab(blepamountgain * (taxRate / 100));
    } else {
      setAmountGain(() => 0);
      setTotalLiab(0);
    }
  }, [fmvEUR, acquiredPriceEUR ,units, taxRate]);

  /* dispatchAmountGainAndTotalLiab */
  useEffect(() => {
    dispatch(
      updateEsppRow({
        date: props.row ? props.row.date : "ss",
        units: units,
        amountGain: amountGain,
        totalLiab: totalLiab,
        company: props.company,
      })
    );
  }, [totalLiab]);
  

  const handleUnitsChange = (value) => {
    setUnits(parseInt(value) || 0);
  };
  const handleAcquiredPriceEURChange = (event) => {
    setAcquiredPriceEUR(parseFloat(event.target.value) || 0); 
  };
  const handleFmvEurChange = (event) => {
    setFmvEur(parseFloat(event.target.value) || 0);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const totalLiabilityRef = useRef();


  return (
    <tr>
      <td style={{ "white-space": "nowrap", "min-width": "170px" }}>
        {props.row && moment(props.row.date).format("DD-MM-YYYY")}
      </td>

      <td>
        {props.row ? props.row.acquiredPriceUSD : <input disabled></input>}
      </td>
      <td>
        {props.row ? (
          props.row.acquiredPriceEUR
        ) : (
          <input
            value={acquiredPriceEUR}
            onChange={handleAcquiredPriceEURChange}
          ></input>
        )}
      </td>
      <td className={columnGroupingStyle}>
        {props.row ? props.row.fmvUSD : <input disabled></input>}
      </td>
      <td>
        {props.row ? (
          props.row.fmvEUR
        ) : (
          <input value={fmvEUR} onChange={handleFmvEurChange}></input>
        )}
      </td>
      <td className={columnGroupingStyle}>
        <InputNumber
          value={units}
          onChange={handleUnitsChange}
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
          min={0}
          max={1000000}
        />
      </td>
      <td>
        <TaxRateSelect
          taxRate={taxRate}
          handleTaxRateChange={(value) => setTaxRate(parseInt(value))}
        />
      </td>

      <td id="totalLiability" ref={totalLiabilityRef}>
        {totalLiab.toFixed(2)}
      </td>
      <td id="amountGain">{amountGain.toFixed(2)}</td>
      <td style={{ "white-space": "nowrap" }}>
        {moment(props.row ? props.row.date : date)
          .add(30, "days")
          .format("YYYY-MM-DD")}
      </td>
      <td>{daysToPay}</td>

      <td>
        {/* <Button
          style={{ margin: "0px", padding: "1px", maxWidth: "94px" }}
          variant="primary"
          size="sm"
          onClick={handleShow}
        >
          Generate RTSO1 form
        </Button> */}
        <Button
          className={generatePdfButtonStyle}
          variant="primary"
          size="sm"
          onClick={handleShow}
          style={{ backgroundColor: "#614CA9" }}
        >
          Generate RTSO1 form
        </Button>
      </td>
      <GeneratePdfModal
        date={date}
        amountGain={amountGain}
        totalLiab={totalLiab}
        show={show}
        extProps={props}
        handleClose={handleClose}
      />
    </tr>
  );
}


const columnGroupingStyle = css`
  border-left: 1px solid #dee2e6; 
`

const generatePdfButtonStyle = css`
  margin: 0px;
  padding: 1px;
  width: 100px;
`;