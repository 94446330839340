import axios from "axios"
import moment from "moment";



// const address = "esppserver.simplecgt.com";
const address =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3001"
    : "https://esppserver.simplecgt.com";

//exchangerate DB
export async function retrieveExchangeRatesFromDb() {
  try {
    return await axios.get(`${address}/api/get`);
  } catch (error) {
    if (error.response) {   // request made, status code out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {   // request made, no response received
      console.log(error.request);
    } else {   // Something happened in setting up the request and triggered an Error
      console.log('Error', error.message);
    }
    console.log(error);

  }
}

  export async function submitExchangeRate(date, rate) {
    const now = moment()
      axios
        .post(`${address}/api/insert`, {
          date: date,
          from: "USD",
          to: "EUR",
          rate: rate,
          created_at: now,
          updated_at: now,
        })
        .then((response) =>
          console.log("response from submit er: ", { response })
        )
        .catch((error) => {
          console.log("error from submit er: ", { error });
        });
  }
  
export async function retrieve(endpoint, date) {
  const url = `${address}/api/${endpoint}`;
  let response
    try {
      response = await axios.get(url,  {params: {date: date}, timeout: 1200})
      return response
  } catch (error) {
    console.log(error);
  }
}