import { degrees, PDFDocument, rgb, StandardFonts } from "pdf-lib";
import fetch from "node-fetch";
import download from "downloadjs";
import fontkit from '@pdf-lib/fontkit'
import moment from "moment";


export default async function modifyPdf(name, address1, address2, address3, ppsn, autoSig, props, date, amountGain, totalLiab ) {
    const url = "/form-rtso1.pdf";
    //   "https://cors-anywhere.herokuapp.com/https://www.revenue.ie/en/additional-incomes/documents/form-rtso1.pdf";
    // const fontUrl = 'https://fonts.gstatic.com/s/homemadeapple/v11/Qw3EZQFXECDrI2q789EKQZJob0x6XHgOiJM6.woff2';
    const fontUrl = '/homemade-apple-v11-latin-regular.ttf';

    const existingPdfBytes = await fetch(url, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    }).then((res) => res.arrayBuffer());

    const fontBytes = await fetch(fontUrl).then(res => res.arrayBuffer());


    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    pdfDoc.registerFontkit(fontkit)

    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const appleFont = await pdfDoc.embedFont(fontBytes);

    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    const { width, height } = firstPage.getSize();

//TODO: object generator 
    firstPage.drawText(`${name}`, {
        x: 33,
        y: height - 110,
        size: 13,
        font: helveticaFont,
        color: rgb(0, 0, 0),
    });

    firstPage.drawText(`${address1}`, {
        x: 300,
        y: height - 105,
        size: 13,
        font: helveticaFont,
        color: rgb(0, 0, 0),
    });
    firstPage.drawText(`${address2}`, {
        x: 300,
        y: height - 120,
        size: 13,
        font: helveticaFont,
        color: rgb(0, 0, 0),
    });
    firstPage.drawText(`${address3}`, {
        x: 300,
        y: height - 137,
        size: 13,
        font: helveticaFont,
        color: rgb(0, 0, 0),
    });

    firstPage.drawText(`${ppsn}`, {
        x: 152,
        y: height - 688,
        size: 15,
        font: helveticaFont,
        color: rgb(0, 0, 0),
    });

    firstPage.drawText(`${moment(props.row ? props.row.date : date).format("DD-MM-YYYY")}`, {
        x: 152,
        y: height - 735,
        size: 15,
        font: helveticaFont,
        color: rgb(0, 0, 0),
    });
    firstPage.drawText(`${Math.round(amountGain)}`, {
        x: 185,
        y: height - 800,
        size: 20,
        font: helveticaFont,
        color: rgb(0, 0, 0),
    });

    firstPage.drawText(`${Math.round(totalLiab)}`, {
        x: 455,
        y: height - 800,
        size: 20,
        font: helveticaFont,
        color: rgb(0, 0, 0),
    });

    //9
    //17

    if (autoSig && name) {
        const length = name.length
        if (length <= 11) {
            firstPage.drawText(name, {
                x: 420,
                y: height - 735,
                size: 23,
                font: appleFont,
                color: rgb(0, 0, 1),
                rotate: degrees(-4),
            });
        }
        else if (length <= 14) {
            firstPage.drawText(name, {
                x: 407,
                y: height - 740,
                size: 22,
                font: appleFont,
                color: rgb(0, 0, 1),
                rotate: degrees(-3),
            });
        }

        else if (length <= 20) {
            firstPage.drawText(name, {
                x: 393,
                y: height - 737,
                size: 18.5,
                font: appleFont,
                color: rgb(0, 0, 1),
                rotate: degrees(-2),
            });
        }
        else if (length <= 27) {
            firstPage.drawText(name, {
                x: 392,
                y: height - 740,
                size: 14,
                font: appleFont,
                color: rgb(0, 0, 1),
                rotate: degrees(1),
            });
        }
        else {
            firstPage.drawText(name, {
                x: 392,
                y: height - 740,
                size: 11,
                font: appleFont,
                color: rgb(0, 0, 1),
                rotate: degrees(1),
            });
        }


    }

    // autoSig &&
    //   firstPage.drawText(name, {
    //     x: 400,
    //     y: height - 744,
    //     size: 16,
    //     font: appleFont,
    //     color: rgb(0, 0, 1),
    //     // color: Blue,
    //     // rotate: degrees(),


    //   });
    // autoSig &&
    //   firstPage.drawImage(sigImage, {
    //     x: 295,
    //     y: height - 940,
    //     size: 20,
    //     font: helveticaFont,
    //     color: rgb(0, 0, 0),
    //   });

    const pdfBytes = await pdfDoc.save();
    download(pdfBytes, `rtso1_${props.row ? props.row.date : date}`, "application/pdf");
}