import React from "react";
import { Select } from 'antd';


export default function TaxRateSelect(props) {
    const { Option } = Select;

    return (
        <Select
            name="taxRate"
            id="taxRate"
            defaultValue={`${props.taxRate}%`}
            onChange={props.handleTaxRateChange}
        >
            <Option value="52">52%</Option>
            <Option value="48.5">48.5%</Option>
            <Option value="46">46%</Option>
        </Select>
    );
};