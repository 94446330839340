import React from 'react';
import './App.css';
import MyHeaderBare from './components/MyHeaderBare';
import ESPPTable from './features/espp/ESPPTable';
import MyJumbo from './components/MyJumbo';

import './App.scss'; //can be included in  src/index.js or App.js file

export default function App() {
  return (
    <React.Fragment>
      <MyHeaderBare/>
      {/* <br></br> */}
      <MyJumbo/>
      <ESPPTable />
    </React.Fragment>
  );
}