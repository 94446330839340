import React, { useState, useEffect, useRef } from "react";
import { css, cx } from '@emotion/css'


import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import modifyPdf from "./GeneratePdf";


export default function GeneratePdfModal(props) {
    const [isSignatureSwitchOn, setIsSignatureSwitchOn] = useState(false);

    const onSignatureSwitchAction = () => {
        setIsSignatureSwitchOn(!isSignatureSwitchOn);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const form = event.currentTarget;
        let name = form.elements.formName.value;
        let address1 = form.elements.formAddress1.value;
        let address2 = form.elements.formAddress2.value;
        let address3 = form.elements.formAddress3.value;
        let ppsn = form.elements.formPPSN.value;
        let autoSig = isSignatureSwitchOn;

        modifyPdf(name, address1, address2, address3, ppsn, autoSig, props.extProps, props.date, props.amountGain, props.totalLiab);
        props.handleClose()
    };

    return (
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>RTSO1 Form generator</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className={css`text-align: center`}>
                    <i>All fields optional, only used to populate RTSO1 form pdf.</i>
                    <br />
                </p>

                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="input" placeholder="Enter Name" />
                        <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>

                    <Form.Group controlId="formAddress1">
                        <Form.Label>Address Line 1</Form.Label>
                        <Form.Control type="input" placeholder="Enter Address Line 1" />
                    </Form.Group>
                    <Form.Group controlId="formAddress2">
                        <Form.Label>Address Line 2</Form.Label>
                        <Form.Control type="input" placeholder="Enter Address Line 2" />
                    </Form.Group>
                    <Form.Group controlId="formAddress3">
                        <Form.Label>Address Line 3</Form.Label>
                        <Form.Control type="input" placeholder="Enter Address Line 3" />
                    </Form.Group>

                    <Form.Group controlId="formPPSN">
                        <Form.Label>PPSN</Form.Label>
                        <Form.Control type="input" placeholder="Enter PPSN" />
                    </Form.Group>

                    <Form.Group controlId="formAutoSignature">
                        <Form.Switch
                            onChange={onSignatureSwitchAction}
                            label="Automatically generate a Signature"
                            checked={isSignatureSwitchOn}
                        />
                    </Form.Group>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={props.handleClose}>Close</Button>
                        <Button variant="primary" type="submit" onClick={props.handleClose}>Save Changes</Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    )
}