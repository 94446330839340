import { createSlice } from '@reduxjs/toolkit'

export const sandboxSlice = createSlice({
  name: "sandbox",
  initialState: [
    { id: 0, totalLiab: 0, amountGain: 0  },
    { id: 1, totalLiab: 0, amountGain: 0  },
    { id: 2, totalLiab: 0, amountGain: 0  },
    ],   //using "createSlice" used immer, meaning we can acheive immutability with regular syntax. immer uses JS tool called a Proxy to warp data, which we then mutate
  reducers: {
    updateSandboxRow: (state, action) =>{
        state.map( row => {
            if(row.id === action.payload.id){
                row.units       = action.payload.units
                row.totalLiab   = action.payload.totalLiab
                row.amountGain  = action.payload.amountGain
            }
        })
    },
    addSandboxRow: (state) =>{
      const lastID = state[state.length - 1].id
      const newID = lastID + 1
      state.push({id: newID, totalLiab: 0, amountGain: 0})
  }
  },
});

export const { updateSandboxRow, addSandboxRow } = sandboxSlice.actions
export default sandboxSlice.reducer
