import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import { css } from '@emotion/css'
import { Button, Tooltip } from 'antd';
import { PlusOutlined, InfoCircleOutlined } from '@ant-design/icons';

import Tab from "react-bootstrap/Tab";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";

import EsppDepositRow_Sandbox from "./EsppDepositRow_Sandbox";
import { addSandboxRow } from "./sandboxSlice";
import { Rtso1Form11CardBody, calcTotalsForForm11 } from "../Rtso1Form11";

import { headerStyle, tableStyle } from "../managed/EsppTab";
import { retrieveExchangeRatesFromDb, submitExchangeRate, retrieve } from "../api";


export default function EsppTab_Sandbox(props) {

  const EsppTabPane = function (props) {
    const [totalLiab, setTotalLiab] = useState(0);
    const [totalAmountGain, setTotalAmountGain] = useState(0);
    const [dbDown, setDbDown] = useState(false);
    const [apiDown, setApiDown] = useState(false);
    

    //TODO: add year to act as validation control for form 11 figures, or diable fields if not all years in rows are equal

    const sandboxState = useSelector((state) => state.sandbox);

    const [exchangeRateCache, setExchangeRateCache] = useState(null);

    function getExchangeRateFromCache(_date) {
      const tempy = exchangeRateCache.filter((row) => row.date === _date)[0]?.rate
      return tempy
    }
    function addExchangeRateToCache(dateKey, _rate) {
      let localExchangeRateCache = [...exchangeRateCache, { date: dateKey, from: "USD", to: "EUR", rate: _rate }]
      setExchangeRateCache(localExchangeRateCache)
    }

    useEffect(async () => {   // on page load, get ERs from DB
      const dateKey = moment().format('YYYY-MM-DD')
      let exchangeRatesFromDB = []
      try {
        const exchangeRatesFromDBresp = await retrieveExchangeRatesFromDb()
        if (!exchangeRatesFromDBresp || exchangeRatesFromDBresp.data.error) {
          console.log("!exchangeRatesFromDBresp || error");
          setDbDown(true)
          throw exchangeRatesFromDBresp?.data?.error
        }
        if (!exchangeRatesFromDBresp.data) {
          console.log("!exchangeRatesFromDBrese.data");
          console.log('exchangeRatesFromDBresp :>> ',exchangeRatesFromDBresp );
          setDbDown(true)
          console.log("empty data, server returning response but db down");
        }
        else { exchangeRatesFromDB = exchangeRatesFromDBresp.data }
      } catch (err) { console.log(err) }
      if (!exchangeRatesFromDB.some((row) => row.date === dateKey)) {
        const exchange_rate_url = `http://api.exchangeratesapi.io/v1/${dateKey}?access_key=${process.env.REACT_APP_EXCHANGE_RATE_ACCESS_KEY}&symbols=USD`;
        await retrieve("getER", dateKey)
        .then((response)=>{
          if (!response || response.data.error) throw response?.data?.error
          console.log("no error from fetching ER from ROW", {response});
          submitExchangeRate(dateKey, 1 / response.data.rates.USD)
          exchangeRatesFromDB = [...exchangeRatesFromDB, { date: dateKey, from: 'USD', to: 'EUR', rate: 1 / response.data.rates.USD }]
        })
        .catch((error)=>{
          console.log("error from fetching ER from TAB", {error});
          setApiDown(true)
        })
      }
      setExchangeRateCache(exchangeRatesFromDB) // [] or response array
    }, [])

    useEffect(() => {
      calcTotalsForForm11(sandboxState, props, setTotalLiab, setTotalAmountGain);
    }, [sandboxState]);

    const dispatch = useDispatch();

    const handleAddRow = () => {
      dispatch(addSandboxRow())
    }

    //TODO: refactor
    const shorCircuit = apiDown && dbDown //if both down, don't render stall div
    let renderStallDiv
    if(shorCircuit){
      renderStallDiv = false
    }else if(!exchangeRateCache || exchangeRateCache.length === 0){
      renderStallDiv = true
    }

    if (renderStallDiv){
      return <div/>
    }
    return (
      <Tab.Pane eventKey={"year"}>
        <Table className={tableStyle}>
          <thead className={headerStyle}>
            <th>Acquired Date</th>
            <th>$ Acquired Price</th>
            <th>$ / €</th>
            <Tooltip title="The actual, likely discounted, price you got the stock for.">
              <th style={{ borderRight: "2px solid #dee2e6" }}>
                € Acquired Price
              </th>
            </Tooltip>
            <th>
              <Tooltip title="Date the shares/options were 'exercised', relevant to the price you are selling them for. Sometimes the same as Acquired Date.">
                <span>
                  Deposit Date <InfoCircleOutlined />
                </span>
              </Tooltip>
            </th>
            <th>$ FMV</th>
            <th>$ / €</th>
            <Tooltip title="The 'Fair Market Value', i.e, the price of the market price of the stock on the deposit date.">
              <th style={{ borderRight: "2px solid #dee2e6" }}>
                € FMV <InfoCircleOutlined />
              </th>
            </Tooltip>
            <th className={columnGroupingStyle}>Units</th>
            <th>Tax Rate</th>
            <th>
              <b>
                {" "}
                € Total Liability <br />
                (pay to revenue)
              </b>
            </th>
            <th>€ Amount of Gain Made on S.O</th>
            <th>RTSO to be paid by</th>
            <th>Days left to pay</th>
            <th></th>
          </thead>

          {sandboxState.map((row, index) => (
            <EsppDepositRow_Sandbox
              key={row.id}
              id={row.id}
              exchangeRateCache={exchangeRateCache}
              getExchangeRateFromCache={getExchangeRateFromCache}
              addExchangeRateToCache={addExchangeRateToCache}
            />
          ))}

          <Button
            onClick={handleAddRow}
            type="primary"
            shape="circle"
            style={{ backgroundColor: "#614CA9" }}
            icon={<PlusOutlined />}
          />
        </Table>
        <Card
          style={{
            "border-radius": ".5rem",
            "box-shadow": "0 2px 4px 0 rgba(0,0,0,.05)",
          }}
        >
          <Card.Header as="h4">For Form 11</Card.Header>
          <Rtso1Form11CardBody
            totalAmountGain={totalAmountGain}
            totalLiab={totalLiab}
          />
        </Card>
      </Tab.Pane>
    );
  };

  return (
    <Fragment>
      {<EsppTabPane />}
    </Fragment>
  );
}


const columnGroupingStyle = css`
  border-left: 1px solid #dee2e6; 
`