import { createSlice } from '@reduxjs/toolkit'

  const reducers =  {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      //   state.value += 1
    },
    decrement: (state) => {
      //   state.value -= 1
    },
    incrementByAmount: (state, action) => {
      //   state.value += action.payload
    },
    updateEsppRow: (state, action) =>{
        state[action.payload.company].map((row) => {
          if (row.date === action.payload.date) {
            row.units = action.payload.units;
            row.totalLiab = action.payload.totalLiab;
            row.amountGain = action.payload.amountGain;
          }
        });
    },
  }

export const esppSlice = createSlice({
  name: "espp",
  initialState: {
    wday: [
    { id: 0, date: "2017-11-30", acquiredPriceUSD: 83.63, acquiredPriceEUR: 70.29,    fmvUSD: 103,    fmvEUR: 86.57 , units: 0, totalLiab: 0, amountGain: 0  },
    { id: 1, date: "2018-05-31", acquiredPriceUSD: 85.44, acquiredPriceEUR: 73.07,    fmvUSD: 130.96, fmvEUR: 112.02, units: 0, totalLiab: 0, amountGain: 0  },
    { id: 2, date: "2018-11-30", acquiredPriceUSD: 107.34, acquiredPriceEUR: 94.83,   fmvUSD: 164.06, fmvEUR: 144.89, units: 0, totalLiab: 0, amountGain: 0  },
    { id: 3, date: "2019-05-31", acquiredPriceUSD: 142.63, acquiredPriceEUR: 127.37,  fmvUSD: 204.12, fmvEUR: 182.28, units: 0, totalLiab: 0, amountGain: 0  },
    { id: 4, date: "2019-11-30", acquiredPriceUSD: 152.25, acquiredPriceEUR: 138.11,  fmvUSD: 179.12, fmvEUR: 162.57, units: 0, totalLiab: 0, amountGain: 0  },
    { id: 5, date: "2020-05-31", acquiredPriceUSD: 146.14, acquiredPriceEUR: 131.45,  fmvUSD: 183.43, fmvEUR: 164.99, units: 0, totalLiab: 0, amountGain: 0  },
    { id: 6, date: "2020-11-30", acquiredPriceUSD: 151.27, acquiredPriceEUR: 126.21,  fmvUSD: 224.79, fmvEUR: 187.76, units: 0, totalLiab: 0, amountGain: 0  },
    { id: 7, date: "2021-05-28", acquiredPriceUSD: 191.84, acquiredPriceEUR: 157.34,  fmvUSD: 228.72, fmvEUR: 187.59, units: 0, totalLiab: 0, amountGain: 0  },
    { id: 8, date: "2021-11-30", acquiredPriceUSD: 192.45, acquiredPriceEUR: 168.88,  fmvUSD: 274.23, fmvEUR: 240.65, units: 0, totalLiab: 0, amountGain: 0  },
    { id: 9, date: "2022-05-31", acquiredPriceUSD: 132.85, acquiredPriceEUR: 123.77,  fmvUSD: 156.30, fmvEUR: 145.62, units: 0, totalLiab: 0, amountGain: 0  },
    { id: 10, date: "2022-11-30", acquiredPriceUSD: 133.07, acquiredPriceEUR: 127.66,  fmvUSD: 167.93,  fmvEUR: 161.10, units: 0, totalLiab: 0, amountGain: 0  },
    { id: 10, date: "2023-05-31", acquiredPriceUSD: 144.06, acquiredPriceEUR: 134.94,  fmvUSD: 211.99	, fmvEUR: 198.57, units: 0, totalLiab: 0, amountGain: 0  },
    { id: 10, date: "2023-11-30", acquiredPriceUSD: 183.01, acquiredPriceEUR: 168.02,  fmvUSD: 	270.72, fmvEUR: 248.55, units: 0, totalLiab: 0, amountGain: 0  },
    ],
    udmy: [
    { id: 0, date: "2022-05-20", acquiredPriceUSD: 10.56, acquiredPriceEUR: 10,     fmvUSD: 12.42, fmvEUR: 11.76, units: 0, totalLiab: 0, amountGain: 0  }, //TODO: 
    { id: 0, date: "2022-11-18", acquiredPriceUSD: 10.56, acquiredPriceEUR: 10.21,  fmvUSD: 15.28, fmvEUR: 14.77, units: 0, totalLiab: 0, amountGain: 0  }, //TODO: 
    { id: 0, date: "2023-05-19", acquiredPriceUSD: 7.61,  acquiredPriceEUR: 7.03,   fmvUSD: 8.95,  fmvEUR: 8.27, units: 0, totalLiab: 0, amountGain: 0  }, //TODO: 
    { id: 0, date: "2023-11-18", acquiredPriceUSD: 0,  acquiredPriceEUR: 0,         fmvUSD: 0,  fmvEUR: 0, units: 0, totalLiab: 0, amountGain: 0  }, //TODO: 
    ],
  },


  // # quick stock price (closing?) in a SS =GOOGLEFINANCE("NASDAQ:UDMY", "price", DATE(2022,11,18))
  //https://seekingalpha.com/symbol/WDAY/historical-price-quotes
  // BUILD and push, when pulling in droplet, get PW for BB from LastP

    //using "createSlice" used immer, meaning we can acheive immutability with regular syntax. immer uses JS tool called a Proxy to warp data, which we then mutate
    reducers : {...reducers}
    // reducers: {
  //   increment: (state) => {
  //     // Redux Toolkit allows us to write "mutating" logic in reducers. It
  //     // doesn't actually mutate the state because it uses the immer library,
  //     // which detects changes to a "draft state" and produces a brand new
  //     // immutable state based off those changes
  //     //   state.value += 1
  //   },
  //   decrement: (state) => {
  //     //   state.value -= 1
  //   },
  //   incrementByAmount: (state, action) => {
  //     //   state.value += action.payload
  //   },
  //   updateEsppRow: (state, action) =>{
  //       state.map( row => {
  //           if(row.date === action.payload.date){
  //               row.units       = action.payload.units
  //               row.totalLiab   = action.payload.totalLiab
  //               row.amountGain  = action.payload.amountGain
  //           }
  //       })
  //   },
  // },
});

export const { increment, decrement, updateEsppRow } = esppSlice.actions
export default esppSlice.reducer

// export const selectEspp = state => state.espp



//for async, use a "Thunk"..  redux-thunk middleware required for store when created (Toolkit's "configureStore" provides)

// export const incrementAsync = amount => dispatch => {
//     setTimeout(() => {
//       dispatch(incrementByAmount(amount))
//     }, 1000)
//   }

//call them with     store.dispatch(incrementAsync(5))
